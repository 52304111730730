import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import UserApi from '../../api/user.api';
import { useUser } from '../../contexts/UserContext';
import Layout from '../../components/Layout/public_layout';

const Login: React.FC = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUser } = useUser();
  const userApi = new UserApi();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await userApi.login(identifier, password);
      localStorage.setItem('token', response.jwt);
      const userData = await userApi.me();
      setUser(userData);
      if (userData.role.type === 'call_center_admin') {
        navigate('/admin/applications');
      } else {
        navigate('/profile');
      }
    } catch (error) {
      console.error('Login failed:', error);
      // Hata mesajını kullanıcıya göster
    }
  };

  return (
    <Layout pageName="Giriş Yap">
      <div className="flex items-center justify-center items-center min-h-screen">
        <Card className="w-96">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Giriş Yap</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="identifier" className="block text-gray-700 text-sm font-bold mb-2">
              E-posta veya Telefon
            </label>
            <input
              type="email"
              id="identifier"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              Şifre
            </label>
            <input
              type="password"
              id="password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <Link to="/apply" className="inline-block align-baseline font-bold text-sm text-gray-500 hover:text-gray-800">
              Kayıt Ol
            </Link>
            <button
              type="submit"
              className="bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Giriş Yap
            </button>
          </div>
        </form>
        </Card>
      </div>
    </Layout>
  );
}

export default Login;
