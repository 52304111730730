import React from 'react';
import Layout from '../../../components/Layout/public_layout';

import ApplicationForm from './application.form';

// FormData tipini tanımlayalım


const Apply: React.FC = () => {
  return (
    <Layout>
      <ApplicationForm />
    </Layout>
  );
};

export default Apply;
