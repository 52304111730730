import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import AdminLayout from '../../../components/Layout/admin_layout';
import ServiceRequestApi from '../../../api/serviceRequest.api';
import { ServiceRequest } from '../../../models/serviceRequest.model';

const ServiceRequestDetail: React.FC = () => {
  const [request, setRequest] = useState<ServiceRequest | null>(null);
  const { documentId } = useParams<{ documentId: string }>();
  const api = new ServiceRequestApi();

  useEffect(() => {
    const fetchRequest = async () => {
      if (documentId) {
        const data = await api.getById(documentId);
        setRequest(data);
      }
    };
    fetchRequest();
  }, [documentId]);



  return (
    <AdminLayout 
      breadcrumbs={[
        { path: '/admin/service-requests', name: 'Servis Talepleri' },
        { path: `/admin/service-requests/${request?.documentId}`, name: `Servis Talep Detayı #${request?.documentId}` }
      ]}
    >
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4">Servis Talebi #{request?.documentId}</h2>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <strong>Cihaz Seri No:</strong> {request?.deviceSerialNo}
          </div>
          <div className="mb-4">
            <strong>Servis Tarihi:</strong> {request?.serviceDate}
          </div>
          <div className="mb-4">
            <strong>Açıklama:</strong> {request?.description}
          </div>
          <div className="mb-4">
            <strong>Oluşturulma Tarihi:</strong> {new Date(request?.createdAt!).toLocaleString()}
          </div>
          <div className="mb-4">
            <strong>Güncellenme Tarihi:</strong> {new Date(request?.updatedAt!).toLocaleString()}
          </div>
          <div className="mb-4">
            <strong>Yayınlanma Tarihi:</strong> {request?.publishedAt ? new Date(request?.publishedAt!).toLocaleString() : 'Yayınlanmamış'}
          </div>
        </div>
        <div className="flex justify-between">
          <Link to="/admin/service-requests" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
            Geri
          </Link>
          <Link to={`/admin/service-requests/${request?.documentId}/edit`} className="bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 text-white font-bold py-2 px-4 rounded">
            Düzenle
          </Link>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ServiceRequestDetail;
