import React, { useCallback, useEffect, useState } from 'react';
import AdminLayout from '../../../components/Layout/admin_layout';
import EquipmentRequestApi from '../../../api/equipmentRequest.api';
import Table, { TableProps } from '../../../components/Table';
import { Link } from 'react-router-dom'; 
import { RequestStatusLabels } from '../../../constants/equipment-request.enum';
import { RequestStatusColors } from '../../../constants/equipment-request.enum';
import EquipmentRequestListFilter from './equipment-request.list.filter';


const EquipmentRequestList: React.FC = () => {
  const [, setFilters] = useState<any>({});
  
  const renderRequestStatus = (item: any) => {
    return <span className={RequestStatusColors[item.request_status] || ''}>{RequestStatusLabels[item.request_status]}</span>
  }

  const [listData, setListData] = useState<TableProps<any>>({
    data:[],
    meta:{},
    columns:[
       {name:"ID",key:"documentId"},
       {name: "Marka",key:"equipmentBrandName"},
       {name: "Model",key:"equipmentModelName"},
       {name: "Başvuru Sahibi",key:"application", render: (item) => <Link className='text-blue-500' to={`/admin/applications/${item.application?.documentId}`}>{item.application?.info?.firstName} {item.application?.info?.lastName}</Link>},  
       {name: "Talep Durumu",key:"request_status", render: renderRequestStatus},
       {name:"Teslim Tarihi",key:"deliveryDate"}, 
       {name:"İşlemler",key:"actions", render: (item) => <div className="flex flex-row space-x-2"><Link to={`/admin/equipment-requests/${item.documentId}/edit`} className="text-blue-500">Detay</Link></div  >}

    ]
});

  const [isLoading, setIsLoading] = useState(true);

  const fetchRequests = useCallback(async (filter: any = {}, pagination: any = { page: 1, pageSize: 10 },api:EquipmentRequestApi = new EquipmentRequestApi()) => {
    setFilters(filter);
    setIsLoading(true);
    const response = await api.getAll(filter, pagination);
    setListData({
      data:response.data.map((item:any) => ({
          documentId: item.documentId,
          deliveryDate: item.deliveryDate,
          equipmentBrandName: item.equipmentBrand?.name,
          equipmentModelName: item.equipmentModel?.name,
          application: item.application,
          request_status: item.request_status,
      })),
      meta:response.meta  || {} ,
      columns:listData.columns
    });
    setIsLoading(false);
  }, [listData.columns]);
  useEffect(() => {
    fetchRequests();
    }, [fetchRequests]);


  return (
    <AdminLayout 
      isLoading={isLoading}
      breadcrumbs={[
        { path: '/admin/equipment-requests', name: 'Cihaz Talepleri' }
      ]}
    >
      <Table
        data={listData.data}
        meta={listData.meta}
        columns={listData.columns}
        header={<EquipmentRequestListFilter onApply={fetchRequests} onClear={fetchRequests} />}
      />
    </AdminLayout>
  );
};

export default EquipmentRequestList;
