import { useState } from "react";
import Input from "../../../components/Input";
import Select from "../../../components/Select";

interface Filter {
    createdAt?: {$gte?:Date | undefined,$lte?:Date | undefined} | undefined;
    id?: string | null;
    info: {
        firstName?: string | null;
        lastName?: string | null;
        
    }
    application_status?: string | null;

}
interface FilterProps {
    onApply: (filters:Filter) => void;
    onClear?: () => void;
}

const ApplicationListFilter = ({onApply,onClear}:FilterProps) => {
    const [filter, setFilter] = useState<Filter>({
        createdAt: {$gte:undefined,$lte:undefined},
        id: "",
        info: {
            firstName:  "",
            lastName: "",
        },
    });

    const handleApply = () => {
        const  filterData = Object.fromEntries(Object.entries(filter).filter(([k, v]) => v));
        const info  =  Object.fromEntries(Object.entries(filterData.info).filter(([k, v]) => v));
        onApply({...filterData, info});
    }

    const handleClear = () => {
        setFilter({...filter, createdAt: {$gte:undefined,$lte:undefined}, id: "", info: {firstName: "", lastName: ""}, application_status: ""});
        onClear && onClear();
    }

    return <div className="grid grid-cols-1  gap-4 w-full items-end justify-between ">        
                <form className="grid grid-cols-4 gap-4 w-full items-between">     
                <Select
                    label="Durum"
                    name="status"
                    items={[
                        { label: "Beklemede", value: "waiting_review" },
                        { label: "İncelemede", value: "under_review" },
                        { label: "Reddedildi", value: "rejected" },
                        { label: "Tamamlandı", value: "completed" },
                    ]}
                    onChange={(e) => setFilter({...filter, application_status: e.target.value})}
                    placeholder="Durum Seçiniz"
                />   
                            <Input type="date" label="Başlangıç Tarihi" name="startDate" value={filter.createdAt?.$gte?.toISOString().split('T')[0] } onChange={(e) => setFilter({...filter, createdAt: {$gte:new Date(e.target.value)}})} />
                            <Input type="date" label="Bitiş Tarihi" name="endDate" value={filter.createdAt?.$lte?.toISOString().split('T')[0] } onChange={(e) => setFilter({...filter, createdAt: {$lte:new Date(e.target.value)}})} />
                            <Input type="number" label="Başvuru Numarası" name="id" value={parseInt(filter.id || "")} onChange={(e) => setFilter({...filter, id: e.target.value})} />   
                            <Input type="text" label="Adı" name="firstName" value={filter.info?.firstName!} onChange={(e) => setFilter({...filter, info: {...filter.info, firstName: e.target.value}})} />
                            <Input type="text" label="Soyadı" name="lastName" value={filter.info?.lastName!} onChange={(e) => setFilter({...filter, info: {...filter.info, lastName: e.target.value}})} />
             
                </form>
                <div className="grid grid-cols-1  gap-4 w-full">
                    <div className="flex flex-row w-full justify-end mt-4 space-x-4">
                    <button type="button" className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" onClick={handleClear}>Temizle</button>
                    <button type="button" className="focus:outline-none text-white bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 focus:ring-4 focus:ring-custom-yellow-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-custom-yellow-green-600 dark:hover:bg-custom-yellow-green-700 dark:focus:ring-custom-yellow-green-800" onClick={handleApply}>Uygula</button>
                    </div>
                </div>
        </div>
                      
      
  
}   

export default ApplicationListFilter;   