import { useParams } from "react-router-dom";
import AdminLayout from "../../../components/Layout/admin_layout";
import { useEffect,useCallback } from "react";
import { useState } from "react";
import ApplicationApi from "../../../api/application.api";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import EquipmentRequestApi from "../../../api/equipmentRequest.api";
import ShipmentRequestApi from "../../../api/shipmentRequest.api";
import ProductRequestApi from "../../../api/productRequest.api";
import ServiceRequestApi from "../../../api/serviceRequest.api";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Checkbox from "../../../components/Checkbox";
import Select from "../../../components/Select";
import { RequestStatusColors } from "../../../constants/equipment-request.enum";
import { RequestStatusLabels } from "../../../constants/equipment-request.enum";
const applicationApi = new ApplicationApi();
const equipmentRequestsApi = new EquipmentRequestApi();
const shipmentRequestsApi = new ShipmentRequestApi();
const productRequestsApi = new ProductRequestApi();
const serviceRequestsApi = new ServiceRequestApi();

const ApplicationDetail: React.FC = () => {
    const { id } = useParams();
    const [application,setApplication] = useState<any>(null);
    const [formData, setFormData] = useState<any>(application);
    const [equipmentRequests,setEquipmentRequests] = useState<any>([]);
    const [shipmentRequests,setShipmentRequests] = useState<any>([]);
    const [productRequests,setProductRequests] = useState<any>([]);
    const [serviceRequests,setServiceRequests] = useState<any>([]);
    const [loading,setLoading] = useState(false);
    const experienceOptions = [
        {label: '1 Yıl', value: 'less_than_one'},
        {label: '1-3 Yıl', value: 'one_to_three'},
        {label: '3-6 Yıl', value: 'three_to_six'},
        {label: '6 Yıl ve daha fazla', value: 'more_than_six'},
    ];
    

    const fetchApplication = useCallback(async () => {
        const response = await applicationApi.getApplication(id!);
        setFormData(response.data);
        setApplication(response.data);
    },[id]);   

    const fetchEquipmentRequests = useCallback(async () => {
        const response = await equipmentRequestsApi.getByApplicationId(id!);
        setEquipmentRequests(response.map((item:any) => ({...item, equipmentBrandName:item.equipmentBrand.name, equipmentModelName:item.equipmentModel.name})));
    },[id]);    

    const fetchShipmentRequests = useCallback(async () => {
        const response = await shipmentRequestsApi.getByApplicationId(id!);
        setShipmentRequests(response);
    },[id]);
    
    const fetchProductRequests = useCallback(async () => {
        const response = await productRequestsApi.getByApplicationId(id!);
        setProductRequests(response);
    },[id]);

    const fetchServiceRequests = useCallback(async () => {
        const response = await serviceRequestsApi.getByApplicationId(id!);
        setServiceRequests(response);
    },[id]);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            fetchApplication(),
            fetchEquipmentRequests(),   
            fetchShipmentRequests(),
            fetchProductRequests(),
            fetchServiceRequests()
        ]).finally(() => setLoading(false));
    }, [fetchApplication,fetchEquipmentRequests,fetchShipmentRequests,fetchProductRequests,fetchServiceRequests]);   



    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        applicationApi.updateApplication(id!,formData).then(() => {
            fetchApplication();
            toast.success('Başvuru başarıyla güncellendi');
          }).finally(() => setLoading(false));
    };

    const handleChange = (section: string | null, field: string, value: any) => {
        if(!section){
            setFormData((prev:any) => ({
                ...prev,
                [field]: value
            }));
            return;
        }

        setFormData((prev:any) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value
            }
        }));
    };


    return <AdminLayout isLoading={loading}>
        <ToastContainer />
        <div className="flex flex-col  h-screen">
            <div className="bg-white p-4 rounded-md shadow-md w-full">
                <div className="flex justify-between items-center mb-6">
                    <div className="text-2xl font-bold">Başvuru Detayı</div>
                    <button 
                        onClick={handleSubmit}
                        className="px-4 py-2 rounded-lg text-white bg-blue-600 hover:bg-blue-700"
                    >
                       Kaydet
                    </button>
                </div>
                <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4" >
                     {/* info */}
                     <div className="grid grid-cols-1 content-start gap-y-2">
                        <Input type="text" label="Adı" name="firstName" value={formData?.info.firstName} onChange={(e) => handleChange('info', 'firstName', e.target.value)} />
                        <Input type="text" label="Soyadı" name="lastName" value={formData?.info.lastName} onChange={(e) => handleChange('info', 'lastName', e.target.value)} />
                        <Input type="text" label="TC Kimlik No" name="idNumber" value={formData?.info.idNumber} onChange={(e) => handleChange('info', 'idNumber', e.target.value)} />
                        <Input type="text" label="Telefon Numarası" name="phoneNumber" value={formData?.info.phoneNumber} onChange={(e) => handleChange('info', 'phoneNumber', e.target.value)} />
                        <Input type="date" label="Doğum Tarihi" name="birthDate" value={formData?.info.birthDate} onChange={(e) => handleChange('info', 'birthDate', e.target.value)} />
                     </div>
                     {/* address */}
                     <div className="grid grid-cols-1 content-start gap-y-2">
                        <Input type="text" label="Şehir" name="city" value={formData?.address.city} onChange={(e) => handleChange('address', 'city', e.target.value)} />
                        <Input type="text" label="İlçe" name="district" value={formData?.address.district} onChange={(e) => handleChange('address', 'district', e.target.value)} />
                        <Input type="text" label="Mahalle" name="street" value={formData?.address.street} onChange={(e) => handleChange('address', 'street', e.target.value)} />
                        <Input type="text" label="Bina No" name="buildingNumber" value={formData?.address.buildingNumber} onChange={(e) => handleChange('address', 'buildingNumber', e.target.value)} />
                        <Input type="text" label="Daire No" name="doorNumber" value={formData?.address.doorNumber} onChange={(e) => handleChange('address', 'doorNumber', e.target.value)} />
                        <Input type="text" label="Kat No" name="floor" value={formData?.address.floor} onChange={(e) => handleChange('address', 'floor', e.target.value)} />
                     </div>
                     {/* skills */}
                     <div className="grid grid-cols-1 content-start gap-y-2">
                        
                        <Select label="Deneyim" name="experience" value={formData?.skills.experience} onChange={(e) => handleChange('skills', 'experience', e.target.value)} items={experienceOptions} />                    
                        <Input type="text" label="Ürün Tipleri" name="productTypes" value={formData?.skills.productTypes.join(', ')} onChange={(e) => handleChange('skills', 'productTypes', e.target.value.split(', '))} />
                        <div className="flex items-center mt-9">
                            <Checkbox label="Dikiş yapabilirim" name="sewing"   checked={formData?.skills.sewing} onChange={(e) => handleChange('skills', 'sewing', e.target.checked.toString())} />
                        </div>
                     </div>
                     
                     {/* application_status */}
                     <div className="grid grid-cols-1 content-start gap-y-2">
                        <Select
                            label="Durum"
                            name="application_status"
                            items={[
                                { label: "Beklemede", value: "waiting_review" },
                                { label: "İncelemede", value: "under_review" },
                                { label: "Reddedildi", value: "rejected" },
                                { label: "Tamamlandı", value: "completed" },
                            ]}
                            onChange={(e) => handleChange(null, 'application_status', e.target.value)}
                            value={formData?.application_status}
                        />
                     </div>
                </form>
                <div className="w-full border-b border-gray-200 my-4"></div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="grid grid-cols-1 content-start gap-y-2">
                    <Table data={equipmentRequests} columns={[
                        {key:'id',  name:'Talep No'},
                        {key:'equipmentBrandName',name:'Marka'},
                        {key:'equipmentModelName',name:'Model'},
                        {key:'createdAt',name:'Oluşturulma Tarihi'},
                        {key:'status',name:'Durum', render : (item:any)=> <span className= {RequestStatusColors[item.request_status]}>{RequestStatusLabels[item.request_status]}</span>},   
                        {key: 'actions', name: 'İşlemler', render: (item: any) => <Link to={`/admin/equipment-requests/${item.documentId}`} className="text-blue-600 hover:text-blue-700">Detay</Link>}
                    ]} header={ <div className="w-full flex justify-between bg-gray-200 p-2 rounded-md">Cihaz İstekleri   <Link to={`/admin/equipment-requests/new?applicationId=${id}`} className="text-blue-600 hover:text-blue-700">Yeni İstek</Link></div>} />
                </div>
                <div className="grid grid-cols-1 content-start gap-y-2">
                <Table data={productRequests} columns={[
                        {key:'id',  name:'Talep No'},
                        {key:'productType',name:'Ürün Tipi'},
                        {key:'quantity',name:'Miktar'},
                        {key:'unit',name:'Birim'},
                        {key: 'actions', name: 'İşlemler', render: (item: any) => <Link to={`/admin/product-requests/${item.documentId}`} className="text-blue-600 hover:text-blue-700">Detay</Link>}
                    ]} header={<div className="w-full flex justify-between bg-gray-200 p-2 rounded-md">Ürün Talepleri   <Link to={`/admin/product-requests/new?applicationId=${id}`} className="text-blue-600 hover:text-blue-700">Yeni İstek</Link></div>} />
                
                </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="grid grid-cols-1 content-start gap-y-2">
                <Table data={shipmentRequests} columns={[
                        {key:'id',  name:'Talep No'},
                        {key:'content',name:'İçerik'},
                        {key:'createdAt',name:'Oluşturulma Tarihi'},
                        {key: 'actions', name: 'İşlemler', render: (item: any) => <Link to={`/admin/shipment-requests/${item.documentId}`} className="text-blue-600 hover:text-blue-700">Detay</Link>}
                        ]} header={<div className="w-full flex justify-between bg-gray-200 p-2 rounded-md">Kargo İstekleri   <Link to={`/admin/shipment-requests/new?applicationId=${id}`} className="text-blue-600 hover:text-blue-700">Yeni İstek</Link></div>} />
                </div>
                
                <div className="grid grid-cols-1 content-start gap-y-2">
                    <Table data={serviceRequests} columns={[
                        {key:'id',  name:'Talep No'},
                        {key:'deviceSerialNo',name:'Cihaz Seri No'},
                        {key:'serviceDate',name:'Servis Tarihi'},
                        {key: 'actions', name: 'İşlemler', render: (item: any) => <Link to={`/admin/service-requests/${item.documentId}`} className="text-blue-600 hover:text-blue-700">Detay</Link>}
                    ]} header={<div className="w-full flex justify-between bg-gray-200 p-2 rounded-md">Servis Talepleri   <Link to={`/admin/service-requests/new?applicationId=${id}`} className="text-blue-600 hover:text-blue-700">Yeni İstek</Link></div>} />
                </div>
                </div>


            </div>
        </div>
    </AdminLayout>
}

export default ApplicationDetail;