import { AxiosInstance } from 'axios';
import { ServiceRequest } from '../models/serviceRequest.model';
import axiosConfig from './axios.config';

export default class ServiceRequestApi {
    private apiClient: AxiosInstance = axiosConfig

  async getAll(): Promise<any> {
    const response = await this.apiClient.get(`/service-requests?populate=*&sort[id]=desc`);
    return response.data;
  }

  async getById(documentId: string): Promise<ServiceRequest> {
    const response = await this.apiClient.get(`/service-requests/${documentId}`);
    return response.data.data;
  }

  async getByApplicationId(applicationId: string): Promise<ServiceRequest[]> {
    const response = await this.apiClient.get(`/service-requests?filters[application][documentId]=${applicationId}`);
    return response.data.data;
  }

  async create(data: Partial<ServiceRequest>): Promise<ServiceRequest> {
    const response = await this.apiClient.post(`/service-requests`, { data });
    return response.data.data;
  }

  async update(documentId: string, data: Partial<ServiceRequest>): Promise<ServiceRequest> {
    delete data.documentId;
    delete data.id;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.publishedAt;
    const response = await this.apiClient.put(`/service-requests/${documentId}`, { data });
    return response.data.data;
  }

  async delete(documentId: string): Promise<void> {
    await this.apiClient.delete(`/service-requests/${documentId}`);
  }
}
