
import { Checkbox as FlowbiteCheckbox, Label } from "flowbite-react";
import { CheckboxProps as FlowbiteCheckboxProps } from "flowbite-react/dist/types/components/Checkbox/Checkbox";


type CheckboxProps = {
    label: string;
} & FlowbiteCheckboxProps;

const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
  return (
    <div className="flex items-center gap-2">
        <Label htmlFor="promotion">{label}</Label>
        <FlowbiteCheckbox {...props} />
       
      </div>
  );
};

export default Checkbox;