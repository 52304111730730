import axios from './axios.config';
import { EquipmentBrand } from '../models/equipmentBrand.model';
import { EquipmentModel } from '../models/equipmentModel.model';
import * as qs from 'qs';

class EquipmentBrandsApi {
  async getAll(): Promise<EquipmentBrand[]> {
    try {
      const response = await axios.get<{ data: EquipmentBrand[] }>('/equipment-brands');
      return response.data.data;
    } catch (error) {
      console.error('Ekipman markaları yüklenirken hata oluştu:', error);
      throw error;
    }
  }

  async getModels(brandId: string): Promise<EquipmentModel[]> {
    try {   
      const filters = qs.stringify({

        filters: {
          equipmentBrand: {
            documentId: { $eq: brandId }
          }
        }
      }, { encodeValuesOnly: true });

      const response = await axios.get<{ data: EquipmentModel[] }>(`/equipment-models?${filters}`);
      return response.data.data;
    } catch (error) {
      console.error('Ekipman modelleri yüklenirken hata oluştu:', error);
      throw error;
    }
  }
}

export default EquipmentBrandsApi;
