import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import AdminLayout from '../../../components/Layout/admin_layout';
import ProductRequestApi from '../../../api/productRequest.api';
import { ProductRequest } from '../../../models/productRequest.model';

const ProductRequestDetail: React.FC = () => {
  const [request, setRequest] = useState<ProductRequest | null>(null);
  const { documentId } = useParams<{ documentId: string }>();
  const api = new ProductRequestApi();

  useEffect(() => {
    const fetchRequest = async () => {
      if (documentId) {
        const data = await api.getById(documentId);
        setRequest(data);
      }
    };
    fetchRequest();
  }, [documentId]);



  return (
    <AdminLayout 
      breadcrumbs={[
        { path: '/admin/product-requests', name: 'Ürün Talepleri' },
        { path: `/admin/product-requests/${request?.documentId}`, name: `Ürün Talep Detayı #${request?.documentId}` }
      ]}
    >
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4">Ürün Talebi #{request?.documentId}</h2>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <strong>Ürün Tipi:</strong> {request?.productType}
          </div>
          <div className="mb-4">
            <strong>Miktar:</strong> {request?.quantity}
          </div>
          <div className="mb-4">
            <strong>Birim:</strong> {request?.unit}
          </div>
          <div className="mb-4">
            <strong>Renk:</strong> {request?.color}
          </div>
          <div className="mb-4">
            <strong>Hedef Ürün:</strong> {request?.targetProduct}
          </div>
          <div className="mb-4">
            <strong>Oluşturulma Tarihi:</strong> {new Date(request?.createdAt!).toLocaleString()}
          </div>
          <div className="mb-4">
            <strong>Güncellenme Tarihi:</strong> {new Date(request?.updatedAt!).toLocaleString()}
          </div>
          <div className="mb-4">
            <strong>Yayınlanma Tarihi:</strong> {request?.publishedAt ? new Date(request?.publishedAt!).toLocaleString() : 'Yayınlanmamış'}
          </div>
        </div>
        <div className="flex justify-between">
          <Link to="/admin/product-requests" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
            Geri
          </Link>
          <Link to={`/admin/product-requests/${request?.documentId}/edit`} className="bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 text-white font-bold py-2 px-4 rounded">
            Düzenle
          </Link>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ProductRequestDetail;
