export const RequestStatusColors:any = {
    waiting_review: "text-yellow-500",
    waiting_payment: "text-orange-500",
    payment_received: "text-green-500",
    delivered:"text-green-500",
    rejected:"text-red-500",
    cancelled:"text-red-500",
    under_review:"text-yellow-500",
    on_delivery:"text-gray-500"
  }
        
export const RequestStatusLabels:any = {
    'waiting_payment': 'Ödeme bekleniyor',
    'under_review': 'İncelemede',
    'payment_received': 'Ödeme Alındı',
    'on_delivery': 'Teslimatta',
    'delivered': 'Teslim Edildi',
    'rejected': 'Reddedildi',
    'cancelled': 'İptal Edildi',
  
  }