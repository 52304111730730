import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/Layout/admin_layout';
import ProductRequestApi from '../../../api/productRequest.api';
import Table, { TableProps } from '../../../components/Table';

const ProductRequestList: React.FC = () => {
  const [listData, setListData] = useState<TableProps<any>>({
    data:[],
    meta:{},
    columns:[
       {name:"ID",key:"documentId"},
       {name: "Ürün Tipi",key:"productType"},
       {name: "Miktar",key:"quantity"},
       {name:"Birim",key:"unit"},
       {name:"Renk",key:"color"},
       {name:"Hedef Ürün",key:"targetProduct"},
    ]
  });
  const [isLoading, setIsLoading] = useState(true);
  const api = new ProductRequestApi();

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    setIsLoading(true);
    const response = await api.getAll();
    setListData({
      data:response.data.map((item:any) => ({
          documentId: item.documentId,
          productType: item.productType,
          quantity: item.quantity,
          unit: item.unit,
          color: item.color,
          targetProduct: item.targetProduct,
      })),
      meta:response.meta,
      columns:listData.columns
    });
    setIsLoading(false);
  };


  return (
    <AdminLayout 
      isLoading={isLoading}
      breadcrumbs={[
        { path: '/admin/product-requests', name: 'Ürün Talepleri' }
      ]}
    >
      <Table
        columns={listData.columns}
        data={listData.data}
        meta={listData.meta}
      />

    </AdminLayout>
  );
};

export default ProductRequestList;
