import { useEffect, useCallback } from "react";
import { useState } from "react";
import ApplicationApi from "../../../api/application.api";
import AdminLayout from "../../../components/Layout/admin_layout";
import Table, { TableProps } from "../../../components/Table";
import ApplicationFormDataModel from "../../../models/applicationFormData.model";
import ApplicationListFilter from "./application.list.filter";
import { Link, useNavigate } from "react-router-dom";
import { ApplicationStatusLabels } from "../../../constants/application.enum";
import { ApplicationStatusColors } from "../../../constants/application.enum";
import { FaPlus } from "react-icons/fa";

const ApplicationList: React.FC = () =>    {
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState<any>({});
    const navigate = useNavigate();
    const [listData, setListData] = useState<TableProps<ApplicationFormDataModel>>({
        data:[],
        meta:{},
        columns:[
            {name:"Başvuru No",key:"id"},
            {name:"Ad Soyad",key:"fullName"},
            {name:"Durum",key:"application_status", render: (item:ApplicationFormDataModel) => <div className={`text-${ApplicationStatusColors[item.application_status as keyof typeof ApplicationStatusColors]} px-2 py-1 rounded-md`}>{ApplicationStatusLabels[item.application_status as keyof typeof ApplicationStatusLabels]}</div>},
            {name:"Oluşturulma Tarihi",key:"createdAt"},
            {name:"İşlemler",key:"actions"}
        ]
    });

    const handlePageClick = (page:number) => {
        fetchApplications(filters,{ page,pageSize:10});
    }

    const fetchApplications = useCallback(async (filters:any = {},pagination:any = { page: 1 ,pageSize:10},api:ApplicationApi = new ApplicationApi()) => {
        setFilters(filters);
        setIsLoading(true);
        try {
            const data = await api.getApplications(filters,pagination);
            setListData({
                data: data.data.map((item:any) => ({...item, fullName: `${item.info.firstName} ${item.info.lastName}`, actions: <Link to={`/admin/applications/${item.documentId}`} className="text-blue-500">Detay</Link> })),
                meta: data.meta || {},
                columns: listData.columns
            });
        } catch (error) {
            console.error("Başvuruları getirirken hata oluştu:", error);
        } finally {
            setIsLoading(false);
        }
    }, [listData.columns]);

    useEffect(() => {
        fetchApplications();
    }, [fetchApplications]);
   
    return <AdminLayout  isLoading={isLoading} breadcrumbs={[{name:"Başvurular",path:"/admin/applications"}]} breadCrumActions={<Link to="/admin/applications/new" className="text-custom-yellow-green-500 flex items-center space-x-2">
        <FaPlus className="flex-shrink-0" />
            Yeni Başvuru</Link>}    >
            <Table data={listData.data} meta={listData.meta} columns={listData.columns}  onPageClick={handlePageClick} header={<ApplicationListFilter onApply={fetchApplications} onClear={fetchApplications}  />}  />
        
    </AdminLayout>;
}

export default ApplicationList;