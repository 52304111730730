import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import EquipmentRequestApi from '../../../api/equipmentRequest.api';
import { EquipmentRequest } from '../../../models/equipmentRequest.model';
import AdminLayout from '../../../components/Layout/admin_layout';
import { RequestStatusColors } from '../../../constants/equipment-request.enum';
import { RequestStatusLabels } from '../../../constants/equipment-request.enum';
import ApplicationFormDataModel from '../../../models/applicationFormData.model';
import ApplicationApi from '../../../api/application.api';


const EquipmentRequestDetail: React.FC = () => {
  const [request, setRequest] = useState<EquipmentRequest | null>(null);
  const [application, setApplication] = useState<ApplicationFormDataModel | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { documentId } = useParams<{ documentId: string }>();
  const api = new EquipmentRequestApi();
  const applicationApi = new ApplicationApi();
  const fetchRequest = async () => {
    if (documentId) {
   
      const data = await api.getById(documentId);
      setRequest(data as EquipmentRequest);
 
      return data;
    }
  };
  const fetchApplication = async (id:string) => {
    if (id) {
      const data = await applicationApi.getApplication(id);
      setApplication(data.data as ApplicationFormDataModel);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchRequest().then(async (data:any) => {
      await fetchApplication(data?.application?.documentId);
      setIsLoading(false);
    });
  }, [documentId]);


  return (
    <AdminLayout 
      isLoading={isLoading}
      breadcrumbs={[
        { path: '/admin/equipment-requests', name: 'Cihaz Talepleri' },
        { path: `/admin/equipment-requests/${request?.documentId}`, name: `Ekipman Talep Detayı #${request?.documentId}` }
      ]}
    >
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4">Talep #{request?.documentId}</h2>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
            <strong>Başvuru Sahibi:</strong> <Link className='text-blue-500' to={`/admin/applications/${request?.application?.documentId}`}>{application?.info?.firstName} {application?.info?.lastName}</Link>
          </div>
          <div className="mb-4">
            <strong>Teslim Tarihi:</strong> {request?.deliveryDate}
          </div>
          <div className="mb-4">
            <strong>Teslim Adresi:</strong> {request?.deliveryAddress}
          </div>
          <div className="mb-4">
            <strong>Ödeme Tipi:</strong> {request?.paymentType}
          </div>
          <div className="mb-4">
            <strong>Ekipman Markası:</strong> {request?.equipmentBrand?.name}
          </div>
          <div className="mb-4">
            <strong>Ekipman Modeli:</strong> {request?.equipmentModel?.name}
          </div>
          <div className="mb-4">
            <strong>Oluşturulma Tarihi:</strong> {new Date(request?.createdAt!).toLocaleString()}
          </div>
          <div className="mb-4">
            <strong>Güncellenme Tarihi:</strong> {new Date(request?.updatedAt!).toLocaleString()}
          </div>
          <div className="mb-4">
            <strong>Yayınlanma Tarihi:</strong> {request?.publishedAt ? new Date(request?.publishedAt!).toLocaleString() : 'Yayınlanmamış'}
          </div>
          <div className="mb-4">
            <strong>Talep Durumu:</strong> <span className={RequestStatusColors[request?.request_status] || ''}>{RequestStatusLabels[request?.request_status]}</span>
          </div>
        </div>
        <div className="flex justify-between">
          <Link to="/admin/equipment-requests" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
            Geri
          </Link>
          <Link to={`/admin/equipment-requests/${request?.documentId}/edit`} className="bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 text-white font-bold py-2 px-4 rounded">
            Düzenle
          </Link>
        </div>
      </div>
    </AdminLayout>
  );
};

export default EquipmentRequestDetail;
