import axios from './axios.config';
import { EquipmentRequest } from '../models/equipmentRequest.model';
import qs from 'qs';

class EquipmentRequestApi {
  async getAll(filters:any = {}, pagination:any = { page: 1, pageSize: 10 }): Promise<any> {
    try {
      const queryString = qs.stringify({filters}, { encode: false });
      const paginationString = qs.stringify({pagination}, { encode: false });
      const response = await axios.get(`/equipment-requests?${queryString}&${paginationString}&populate[equipmentBrand]=*&populate[equipmentModel]=*&populate[application][populate][info]=*&sort[id]=desc`);
      return response.data;
    } catch (error) {
      console.error('Cihaz Talepleri yüklenirken hata oluştu:', error);
      throw error;
    }
  }

  async getById(id: string): Promise<EquipmentRequest> {
    try {
      const response = await axios.get<{ data: EquipmentRequest }>(`/equipment-requests/${id}?populate[application][info]=*&populate[application][skills]=*&populate[application][address]=*&populate[equipmentBrand]=*&populate[equipmentModel]=*`);
      return response.data.data;
    } catch (error) {
      console.error('Ekipman talebi yüklenirken hata oluştu:', error);
      throw error;
    }
  }

  async getByApplicationId(applicationId: string): Promise<EquipmentRequest[]> {
    const response = await axios.get<{ data: EquipmentRequest[] }>(`/equipment-requests?filters[application][documentId]=${applicationId}&populate[equipmentBrand]=*&populate[equipmentModel]=*`);
    return response.data.data;
  }

  async create(data: Partial<EquipmentRequest>): Promise<EquipmentRequest> {
    try {
      const response = await axios.post<{ data: EquipmentRequest }>('/equipment-requests', { data });
      return response.data.data;
    } catch (error) {
      console.error('Ekipman talebi oluşturulurken hata oluştu:', error);
      throw error;
    }
  }

  async update(id: string, data: Partial<EquipmentRequest>): Promise<EquipmentRequest> {
    try {
        delete data.documentId;
        delete data.id;
        delete data.createdAt;
        delete data.updatedAt;
        delete data.publishedAt;
        const response = await axios.put<{ data: EquipmentRequest }>(`/equipment-requests/${id}`, { data });
        return response.data.data;
    } catch (error) {
        console.error('Ekipman talebi güncellenirken hata oluştu:', error);
        throw error;
    }
  }

  async delete(id: string): Promise<void> {
    try {
      await axios.delete(`/equipment-requests/${id}`);
    } catch (error) {
      console.error('Ekipman talebi silinirken hata oluştu:', error);
      throw error;
    }
  }
}

export default EquipmentRequestApi;
