import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminLayout from '../../../components/Layout/admin_layout';
import ShipmentRequestApi from '../../../api/shipmentRequest.api';
import { ShipmentRequest } from '../../../models/shipmentRequest.model';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShipmentRequestForm: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const navigate = useNavigate();
  const api = new ShipmentRequestApi();

  const [formData, setFormData] = useState<Partial<ShipmentRequest>>({
    date: '',
    quantity: '',
    unit: '',
    content: '',
    deliveryAddress: '',
  });

  useEffect(() => {
    const fetchRequest = async () => {
      if (documentId) {
        try {
          const data = await api.getById(documentId);
          setFormData(data);
        } catch (error) {
          console.error('Talep yüklenirken hata oluştu:', error);
        }
      }
    };

    fetchRequest();
  }, [documentId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (documentId) {
        await api.update(documentId, formData);
      } else {
        const applicationId = new URLSearchParams(window.location.search).get('applicationId');
        if(!applicationId){
          toast.error('Başvuru numarası bulunamadı!');
          return;
        }
        formData.application = { connect: applicationId };
        await api.create(formData);
      }
      toast.success('Sevkiyat talebi başarıyla oluşturuldu!');
      navigate('/admin/shipment-requests');
    } catch (error) {
      console.error('Sevkiyat talebi oluşturulurken bir hata oluştu:', error);
      toast.error('Sevkiyat talebi oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  return (
    <AdminLayout 
      breadcrumbs={[
        { path: '/shipment-requests', name: 'Kargo Talepleri' }
      ]}
    >
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="container mx-auto p-4">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
              Sevkiyat Tarihi
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="date"
              type="date"
              name="date"
              value={formData.date?.split('T')[0]}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
              Miktar
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="quantity"
              type="text"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="unit">
              Birim
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="unit"
              type="text"
              name="unit"
              value={formData.unit}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
              İçerik
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="content"
              type="text"
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deliveryAddress">
              Teslimat Adresi
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="deliveryAddress"
              name="deliveryAddress"
              value={formData.deliveryAddress}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {documentId ? 'Güncelle' : 'Oluştur'}
            </button>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default ShipmentRequestForm;
