/* eslint-disable jsx-a11y/scope */
import { useState } from "react";

export type TableProps<T>  = {
 
    data?:T[];
    meta?: {
            pagination?: {
                page?: number;
                pageCount?: number;
                pageSize?: number;
                total?: number;
            }
    }

    columns?: {
        name: string;
        key: string;
        render?: (item: T) => React.ReactNode;
    }[];
    header?: React.ReactNode;
    footer?: React.ReactNode;
    onPageClick?: (page: number) => void;
}


const Table = <T,>({ data, columns, meta, header,onPageClick }: TableProps<T>) => {
    
    const [currentPage,setCurrentPage] = useState(1);
    const [pageStartIndex,setPageStartIndex] = useState(0);
    

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
        onPageClick && onPageClick(page);
    }

    const handleNextPageClick = () => {
        setCurrentPage(currentPage + 1);
        onPageClick && onPageClick(currentPage + 1);
    }

    const handlePreviousPageClick = () => {
        setCurrentPage(currentPage - 1);
        onPageClick && onPageClick(currentPage - 1);
    }

    return <section className="bg-gray-50 dark:bg-gray-900 ">

        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                {header}
            </div>
            <div className="overflow-x-auto min-h-[12rem]">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {columns?.map((column) => (  
                                <th scope="col" className="px-4 py-3">{column.name}</th>
                            ))}
                        
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item) => (                       
                            <tr className="border-b dark:border-gray-700">
                                    {columns?.map((column) => (
                                    <td scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{column.render ? column.render(item) : (item[column.key as keyof T] as string)}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                <span className="font-semibold text-gray-900 dark:text-white">{meta?.pagination?.total}</span> kayıttan
                    <span className="font-semibold text-gray-900 dark:text-white"> {meta?.pagination?.page} - { Math.min(meta?.pagination?.total || 0,   meta?.pagination?.pageSize || 0 * (meta?.pagination?.page || 0))}</span>
                    arası gösteriliyor
                  
                </span>
                <ul className="inline-flex items-stretch -space-x-px">
                    <li>
                        <button onClick={handlePreviousPageClick} className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="sr-only">Previous</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </li>
                     { pageStartIndex > 0 && (
                        <li>
                            <button onClick={() => setPageStartIndex(Math.max(0, pageStartIndex - 5))} className="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">...</button>
                        </li>
                     )}
                    {Array.from({ length: Math.min(9, Math.min(meta?.pagination?.pageCount || 0, pageStartIndex + 9) - pageStartIndex) }, (_, index) => (
                        <li>
                            <button onClick={()=>handlePageClick(pageStartIndex + index + 1)} className={"flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white " + (pageStartIndex + index + 1 === currentPage ? "bg-green-800 text-white" : "")}>{ pageStartIndex +index + 1}</button>
                        </li>
                    ))}
                    {meta?.pagination?.pageCount && meta?.pagination?.pageCount > 9 &&  Math.min(meta?.pagination?.pageCount || 0, pageStartIndex + 9) < meta?.pagination?.pageCount && (
                        <li>
                            <button onClick={() => setPageStartIndex(Math.min(meta?.pagination?.pageCount || 0, pageStartIndex + 5))} className="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">...</button>
                        </li>
                    )}
            

                    <li>
                        <button onClick={handleNextPageClick} className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="sr-only">Next</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>

    </section>
}

export default Table;