import React from 'react';
import Layout from '../../components/Layout/public_layout';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { FaApple, FaGooglePlay } from 'react-icons/fa';

const Home: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Layout pageName="Anasayfa">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-6 text-center text-lemon-ginger">
          Anadolu'nun El Emeği, Kadının Gücü: Kadıneli'ne Hoş Geldiniz
        </h1>
        
        <div className="mb-12 text-center">
          <p className="text-xl mb-4">
            Binlerce yıllık el sanatları geleneğimizi, modern tasarımlarla buluşturan ve kadınlarımızın ekonomik bağımsızlığına güç katan yenilikçi platformumuzda yerinizi alın.
          </p>
          <Slider {...settings} className="rounded-lg shadow-lg mb-8">
            <div className="relative">
              <img 
                src="https://images.unsplash.com/photo-1531456786827-de29dd0fa8b7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="Geleneksel Dokuma" 
                className="w-full h-[600px] object-cover" 
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent text-white p-6">
                <h3 className="text-2xl font-semibold">Anadolu'nun Dokuma Sanatı</h3>
                <p className="text-lg">Yüzyıllık geleneklerimizi yaşatıyoruz</p>
              </div>
            </div>
            <div className="relative">
              <img 
                src="https://images.unsplash.com/photo-1536867520774-5b4f2628a69b?q=100&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="El İşi Detay" 
                className="w-full h-[600px] object-cover" 
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent text-white p-6">
                <h3 className="text-2xl font-semibold">El Emeği, Göz Nuru</h3>
                <p className="text-lg">Her üründe bir kadının hikayesi var</p>
              </div>
            </div>
            <div className="relative">
              <img 
                src="https://images.unsplash.com/photo-1518732836484-bd257665c9d1?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="Modern Tasarımlar" 
                className="w-full h-[600px] object-cover" 
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent text-white p-6">
                <h3 className="text-2xl font-semibold">Geleneksel Motifler, Modern Tasarımlar</h3>
                <p className="text-lg">Zarafet ve stil bir arada</p>
              </div>
            </div>
          </Slider>
        </div>

        {/* Değerler ve Misyon Bölümü */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-12">
          <div className="md:col-span-2">
            <h2 className="text-2xl font-semibold mb-4 text-lemon-ginger">Değerlerimiz ve Misyonumuz</h2>
            <p className="text-lg mb-4 leading-relaxed">
              Kadıneli, Anadolu'nun zengin el sanatları mirasını yaşatırken, kadınlarımızın ekonomik özgürlüğünü destekleyen bir köprü görevi görüyor. Her bir dikişte, her bir nakışta binlerce yıllık geleneğimizi modern dünyayla buluşturuyoruz.
            </p>
            <p className="text-lg leading-relaxed">
              Evlerinde üretim yapan kadınlarımız, geleneksel el sanatlarımızı yaşatırken aile ekonomilerine katkı sağlıyor. Bu yolculukta, sürdürülebilir üretim anlayışıyla çevremizi korurken, kültürel mirasımızı da gelecek nesillere aktarıyoruz.
            </p>
            <div className="mt-6 grid grid-cols-3 gap-4">
              <div className="text-center p-4 bg-turmeric/10 rounded-lg">
                <h3 className="font-bold text-xl text-lemon-ginger">500+</h3>
                <p className="text-sm">Aktif Üretici Kadın</p>
              </div>
              <div className="text-center p-4 bg-turmeric/10 rounded-lg">
                <h3 className="font-bold text-xl text-lemon-ginger">15+</h3>
                <p className="text-sm">İl Genelinde Faaliyet</p>
              </div>
              <div className="text-center p-4 bg-turmeric/10 rounded-lg">
                <h3 className="font-bold text-xl text-lemon-ginger">10000+</h3>
                <p className="text-sm">Tamamlanan Sipariş</p>
              </div>
            </div>
          </div>
          <div className="md:col-span-1">
            <img 
              src="https://images.unsplash.com/photo-1597843797221-e34b4a320b97?ixlib=rb-4.0.3" 
              alt="El Sanatları" 
              className="w-full h-64 object-cover rounded-lg shadow-md" 
            />
          </div>
        </div>

        {/* Üretim Süreci */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center text-lemon-ginger">Kadıneli'nde Üretim Yolculuğu</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center hover:transform hover:scale-105 transition-transform duration-300">
             
                <img src="https://images.unsplash.com/photo-1474128670149-7082a8d370ea?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Hammadde" className=" w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4" />
             
              <h3 className="text-xl font-semibold mb-3">Hammadde Temini</h3>
              <p>Sürdürülebilir kaynaklardan elde edilen kaliteli malzemeler</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center hover:transform hover:scale-105 transition-transform duration-300">
            <img src="https://images.unsplash.com/photo-1519452575417-564c1401ecc0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Üretim" className=" w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-3">Eğitim ve Destek</h3>
              <p>Uzman eğitmenlerden sürekli mentorluk desteği</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center hover:transform hover:scale-105 transition-transform duration-300">
              
                <img src="https://plus.unsplash.com/premium_photo-1673958391039-7079054d17c5?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Üretim" className=" w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4" />
            
              <h3 className="text-xl font-semibold mb-3">Ev Tabanlı Üretim</h3>
              <p>Esnek çalışma saatleriyle ev ortamında üretim</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center hover:transform hover:scale-105 transition-transform duration-300">
            <img src="https://images.unsplash.com/photo-1555529733-0e670560f7e1?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Üretim" className=" w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-3">Pazarlama ve Satış</h3>
              <p>Global pazarlara erişim ve profesyonel satış desteği</p>
            </div>
          </div>
        </div>

        {/* Başarı Hikayeleri */}
        <div className="mb-16 bg-turmeric/5 p-8 rounded-lg">
          <h2 className="text-3xl font-bold mb-8 text-center text-lemon-ginger">İlham Veren Başarı Hikayeleri</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <img 
                src="https://images.unsplash.com/photo-1543933573-1e0a7578328f?q=80&w=1959&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="Ayşe Hanım" 
                className="w-full h-48 object-cover rounded-lg mb-4" 
              />
              <h3 className="text-xl font-semibold mb-2">Ayşe Hanım'ın Yolculuğu</h3>
              <p className="text-gray-700 mb-4">
                "30 yıllık el nakışı tecrübemle artık dünya pazarlarına açıldım. Kadıneli sayesinde siparişlere yetişemiyorum. Geleneksel motiflerimizi modern tasarımlarla buluşturarak yurt dışına ihraç ediyoruz."
              </p>
              <span className="text-texas-rose font-semibold">İstanbul, Üsküdar</span>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <img 
                src="https://images.unsplash.com/photo-1655462502318-a5ff79542ce7?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="Fatma Hanım" 
                className="w-full h-48 object-cover rounded-lg mb-4" 
              />
              <h3 className="text-xl font-semibold mb-2">Fatma Hanım'ın Başarısı</h3>
              <p className="text-gray-700 mb-4">
                "Ev hanımıydım, şimdi 12 kadına istihdam sağlayan bir atölyem var. Kadıneli'nin eğitimleri ve destekleriyle kendi işimin patronu oldum. Şimdi yöresel dokuma tekniklerimizi gelecek nesillere aktarıyorum."
              </p>
              <span className="text-texas-rose font-semibold">Denizli, Buldan</span>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <img 
                src="https://images.unsplash.com/photo-1624525005654-c828ed32950d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="Zeynep Hanım" 
                className="w-full h-48 object-cover rounded-lg mb-4" 
              />
              <h3 className="text-xl font-semibold mb-2">Zeynep Hanım'ın Hikayesi</h3>
              <p className="text-gray-700 mb-4">
                "İki çocuk annesiydim ve evden çıkamıyordum. Kadıneli ile tanıştıktan sonra evimde üretim yapmaya başladım. Şimdi çocuklarımın eğitim masraflarını karşılayabiliyorum ve kendi ayaklarımın üzerinde duruyorum."
              </p>
              <span className="text-texas-rose font-semibold">Gaziantep, Şahinbey</span>
            </div>
          </div>
        </div>

        {/* Mobil Uygulama Bölümü */}
        <div className="bg-turmeric p-12 rounded-2xl shadow-xl mb-16 text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Kadıneli Mobil Uygulaması</h2>
              <p className="text-lg mb-8">
                Üretimden satışa, eğitimden mentorluğa kadar tüm Kadıneli hizmetleri artık cebinizde! 
                Sipariş takibi, gelir raporları ve daha fazlası...
              </p>
              <div className="flex flex-wrap gap-4">
                <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer" 
                   className="flex items-center bg-black text-white px-6 py-3 rounded-lg hover:bg-gray-800 transition duration-300">
                  <FaApple className="text-2xl mr-2" />
                  <div>
                    <div className="text-xs">İNDİR</div>
                    <div className="text-sm font-semibold">App Store</div>
                  </div>
                </a>
                <a href="https://play.google.com" target="_blank" rel="noopener noreferrer" 
                   className="flex items-center bg-black text-white px-6 py-3 rounded-lg hover:bg-gray-800 transition duration-300">
                  <FaGooglePlay className="text-2xl mr-2" />
                  <div>
                    <div className="text-xs">HEMEN İNDİR</div>
                    <div className="text-sm font-semibold">Google Play</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="hidden md:block">
              <img 
                src="https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="Kadıneli Mobil Uygulama" 
                className="w-full max-w-md mx-auto"
              />
            </div>
          </div>
        </div>

        {/* Katılım Çağrısı */}
        <div className="text-center mb-12 bg-turmeric/5 p-12 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-6 text-lemon-ginger">Hayallerinize Kadıneli ile Ulaşın!</h2>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            Siz de el emeğinizi değere dönüştürmek, ekonomik özgürlüğünüzü kazanmak ve güçlü bir kadın topluluğunun parçası olmak ister misiniz? 
            Kadıneli ailesine katılın, başarı hikayenizi birlikte yazalım!
          </p>
          <Link to="/apply">
            <button className="bg-lemon-ginger hover:bg-texas-rose text-white font-bold py-4 px-8 rounded-full text-lg transition duration-300 transform hover:scale-105">
              Hemen Başvurun
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
