import { useState } from "react";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { RequestStatusLabels } from "../../../constants/equipment-request.enum";

interface Filter {
    deliveryDate?: {$gte?:Date | undefined,$lte?:Date | undefined} | undefined;
    createdAt?: {$gte?:Date | undefined,$lte?:Date | undefined} | undefined;
    id?: string | null;
    documentId?: string | null;
    application?: {
        info: {
            firstName?: string | null;
            lastName?: string | null;
        }
        documentId?: string | null;
    }
    request_status?: string | null;
    equipmentBrand?: {
        name?: string | null;
    }
    equipmentModel?: {
        name?: string | null;
    }

}
interface FilterProps {
    onApply: (filters:Filter) => void;
    onClear?: () => void;
}

const EquipmentRequestListFilter = ({onApply,onClear}:FilterProps) => {
    const [filter, setFilter] = useState<Filter>({
        deliveryDate: {$gte:undefined,$lte:undefined},
        createdAt: {$gte:undefined,$lte:undefined},
        id: "",
        documentId: "",
        application: {
            info: {
                firstName:  "",
                lastName: "",
            },
            documentId: ""
        },
        request_status: "",
        equipmentBrand: {
            name: ""
        },
        equipmentModel: {
            name: ""
        }
    });

    const handleApply = () => {
        const  filterData = Object.fromEntries(Object.entries(filter).filter(([k, v]) => v));
        
        const equipmentBrand  =  Object.fromEntries(Object.entries(filterData.equipmentBrand).filter(([k, v]) => v));
        const equipmentModel  =  Object.fromEntries(Object.entries(filterData.equipmentModel).filter(([k, v]) => v));
        const application =  Object.fromEntries(Object.entries(filterData.application).filter(([k, v]) => v));
        const info  =  Object.fromEntries(Object.entries(filterData.application.info).filter(([k, v]) => v));
        onApply({...filterData, application: {...application, info}, equipmentBrand, equipmentModel});
    }

    const handleClear = () => {
        setFilter({...filter, deliveryDate: {$gte:undefined,$lte:undefined}, createdAt: {$gte:undefined,$lte:undefined}, id: "", documentId: "", application: {info: {firstName: "", lastName: ""}, documentId: ""}, request_status: "", equipmentBrand: {name: ""}, equipmentModel: {name: ""}});
        onClear && onClear();
    }

    return <div className="grid grid-cols-1  gap-4 w-full items-end justify-between ">        
                <form className="grid grid-cols-1 gap-4 w-full items-between">     
                <div className="grid grid-cols-4 gap-4">
                <Input type="text" label="Talep Numarası" name="documentId" value={filter.documentId || ""} onChange={(e) => setFilter({...filter, documentId: e.target.value})} />
                <Select
                    label="Durum"
                    name="status"
                    items={
                        [
                            { label: RequestStatusLabels.under_review, value: 'under_review' },
                            { label: RequestStatusLabels.waiting_payment, value: 'waiting_payment' },
                            { label: RequestStatusLabels.payment_received, value: 'payment_received' },
                            { label: RequestStatusLabels.on_delivery, value: 'on_delivery' },
                            { label: RequestStatusLabels.delivered, value: 'delivered' },
                            { label: RequestStatusLabels.rejected, value: 'rejected' },
                            { label: RequestStatusLabels.cancelled, value: 'cancelled' },
                            { label: RequestStatusLabels.waiting_review, value: 'waiting_review' },
                        ]
                    }
                    onChange={(e) => setFilter({...filter, request_status: e.target.value})}
                    placeholder="Durum Seçiniz"
                />   
     
                <Input type="date" label="Talep Başlangıç Tarihi" name="startDate" value={filter.createdAt?.$gte?.toISOString().split('T')[0] } onChange={(e) => setFilter({...filter, createdAt: {$gte:new Date(e.target.value)}})} />
                <Input type="date" label="Talep Bitiş Tarihi" name="endDate" value={filter.createdAt?.$lte?.toISOString().split('T')[0] } onChange={(e) => setFilter({...filter, createdAt: {$lte:new Date(e.target.value)}})} />
                    </div>
                <div className="grid grid-cols-4 gap-4">
                            <Input type="date" label="Teslimat Başlangıç Tarihi" name="deliveryStartDate" value={filter.deliveryDate?.$gte?.toISOString().split('T')[0] } onChange={(e) => setFilter({...filter, deliveryDate: {$gte:new Date(e.target.value)}})} />
                            <Input type="date" label="Teslimat Bitiş Tarihi" name="deliveryEndDate" value={filter.deliveryDate?.$lte?.toISOString().split('T')[0] } onChange={(e) => setFilter({...filter, deliveryDate: {$lte:new Date(e.target.value)}})} />
                            <Input type="text" label="Marka" name="equipmentBrandName" value={filter.equipmentBrand?.name || ""} onChange={(e) => setFilter({...filter, equipmentBrand: {...filter.equipmentBrand, name: e.target.value}})} />
                            <Input type="text" label="Model" name="equipmentModelName" value={filter.equipmentModel?.name || ""} onChange={(e) => setFilter({...filter, equipmentModel: {...filter.equipmentModel, name: e.target.value}})} />
                            
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                        <Input type="number" label="Başvuru Numarası" name="id" value={filter.application?.documentId || ""} onChange={(e) => setFilter({...filter, application: {...filter.application, documentId: e.target.value, info: {...filter.application?.info}}})} />   
                        <Input type="text" label="Adı" name="firstName" value={filter.application?.info?.firstName || ""} onChange={(e) => setFilter({...filter, application: {...filter.application, info: {...filter.application?.info, firstName: e.target.value}}})} />
                        <Input type="text" label="Soyadı" name="lastName" value={filter.application?.info?.lastName || ""} onChange={(e) => setFilter({...filter, application: {...filter.application, info: {...filter.application?.info, lastName: e.target.value}}})} />
                    </div>
             
                </form>
                <div className="grid grid-cols-1  gap-4 w-full">
                    <div className="flex flex-row w-full justify-end mt-4 space-x-4">
                        <button type="button" className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" onClick={handleClear}>Temizle</button>
                        <button type="button" className="focus:outline-none text-white bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 focus:ring-4 focus:ring-custom-yellow-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-custom-yellow-green-600 dark:hover:bg-custom-yellow-green-700 dark:focus:ring-custom-yellow-green-800" onClick={handleApply}>Uygula</button>
                    </div>
                </div>
        </div>
                      
      
  
}   

export default EquipmentRequestListFilter;   