import { AxiosInstance } from "axios";
import axiosConfig from "./axios.config";
import ApplicationFormDataModel from "../models/applicationFormData.model";
import qs from 'qs';
export default class ApplicationApi {
    private apiClient: AxiosInstance = axiosConfig

    async createApplication(application: ApplicationFormDataModel): Promise<any> {
        const response = await this.apiClient.post('/applications', { data: application });
        return response.data;
    }

    async getApplication(id: string): Promise<any> {
        const response = await this.apiClient.get(`/applications/${id}?populate=*`);
        return response.data;
    }

    async getApplications(filters: any,pagination:any = { page: 1 ,pageSize:10}): Promise<any> {
        const filterString = qs.stringify({filters}, { encode: false });
        const paginationString = qs.stringify({pagination}, { encode: false });
        const response = await this.apiClient.get(`/applications?${filterString}&populate[info]=*&populate[skills]=*&populate[address]=*&populate[shipment_requests]=*&populate[product_requests]=*&populate[service_requests]=*&populate[equipment_requests]=*&${paginationString}&sort[id]=desc`);
        return response.data;
    }

    async updateApplication(id: string, application: ApplicationFormDataModel): Promise<any> {
        delete application.id;
        delete application.createdAt;
        delete application.updatedAt;
        delete application.documentId;
        delete application.skills.id;
        delete application.info.id;
        delete application.address.id;
        delete application.equipment_requests;
        delete application.shipment_requests;
        delete application.product_requests;
        delete application.service_requests;

        const response = await this.apiClient.put(`/applications/${id}`, { data: application });
        return response.data;
    }

}   