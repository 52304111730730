import { AxiosInstance } from 'axios';
import { ProductRequest } from '../models/productRequest.model';
import axiosConfig from './axios.config';

export default class ProductRequestApi {
    private apiClient: AxiosInstance = axiosConfig


  async getAll(): Promise<any> {
    const response = await this.apiClient.get(`/product-requests?populate=*&sort[id]=desc`);
    return response.data;
  }

  async getById(documentId: string): Promise<ProductRequest> {
    const response = await this.apiClient.get(`/product-requests/${documentId}`);
    return response.data.data;
  }

  async getByApplicationId(applicationId: string): Promise<ProductRequest[]> {
    const response = await this.apiClient.get(`/product-requests?filters[application][documentId]=${applicationId}`);
    return response.data.data;
  }

  async create(data: Partial<ProductRequest>): Promise<ProductRequest> {
    const response = await this.apiClient.post(`/product-requests`, { data });
    return response.data.data;
  }

  async update(documentId: string, data: Partial<ProductRequest>): Promise<ProductRequest> {
    delete data.documentId;
    delete data.id;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.publishedAt;
    const response = await this.apiClient.put(`/product-requests/${documentId}`, { data });
    return response.data.data;
  }

  async delete(documentId: string): Promise<void> {
    await this.apiClient.delete(`/product-requests/${documentId}`);
  }
}
