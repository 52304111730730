import axios from "axios";

const publicEndpoints = ['/auth/local', '/auth/local/register'];

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL, //replace with your BaseURL
    headers: {
      'Content-Type': 'application/json', // change according header type accordingly
    },
  });
  axiosClient.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('token'); // get stored access token
      
      if (accessToken && !publicEndpoints.includes(config.url!)) {
        config.headers.Authorization = `Bearer ${accessToken}`; // set in header
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if(error.response.status === 403){
        localStorage.removeItem('token');
        window.location.href = '/auth/login';
      }
      return Promise.reject(error);
    }
  );

export default axiosClient;