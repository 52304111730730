import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/Layout/admin_layout';
import ServiceRequestApi from '../../../api/serviceRequest.api';
import Table, { TableProps } from '../../../components/Table';

const ServiceRequestList: React.FC = () => {
  const [listData, setListData] = useState<TableProps<any>>({
    data:[],
    meta:{},
    columns:[
       {name:"ID",key:"documentId"},
       {name: "Cihaz Seri No",key:"deviceSerialNo"},
       {name:"Servis Tarihi",key:"serviceDate"},
    ]
  }); 
  const [isLoading, setIsLoading] = useState(true);
  const api = new ServiceRequestApi();

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    setIsLoading(true);
    const response = await api.getAll();
    setListData({
      data:response.data.map((item:any) => ({
          documentId: item.documentId,
          deviceSerialNo: item.deviceSerialNo,
          serviceDate: item.serviceDate,
      })),
    });
    setIsLoading(false);
  };

  const handleDelete = async (documentId: string) => {
    if (window.confirm('Bu talebi silmek istediğinizden emin misiniz?')) {
      await api.delete(documentId);
      fetchRequests();
    }
  };

  return (
    <AdminLayout 
      isLoading={isLoading}
      breadcrumbs={[
        { path: '/admin/service-requests', name: 'Servis Talepleri' }
      ]}>
      <Table
        columns={listData.columns}
        data={listData.data}
        meta={listData.meta}
      />

    </AdminLayout>
  );
};

export default ServiceRequestList;
