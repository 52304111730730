import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../../components/Layout/admin_layout';
import ShipmentRequestApi from '../../../api/shipmentRequest.api';
import { ShipmentRequest } from '../../../models/shipmentRequest.model';
import Table, { TableProps } from '../../../components/Table';

const ShipmentRequestList: React.FC = () => {
  const [listData, setListData] = useState<TableProps<any>>({
    data:[],
    meta:{},
    columns:[
       {name:"ID",key:"documentId"},
       {name: "İçerik",key:"content"},
       {name: "Miktar",key:"quantity"},
       {name:"Birim",key:"unit"},
       {name:"Sevkiyat Tarihi",key:"date"},
       {name:"İşlemler",key:"actions"}

    ]
});
  const [isLoading, setIsLoading] = useState(true);
  const api = new ShipmentRequestApi();

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    setIsLoading(true);
    const response = await api.getAll();
    setListData({
      data:response.data.map((item:any) => ({
          documentId: item.documentId,
          equipmentBrandName: item.equipmentBrandName,
          equipmentModelName: item.equipmentModelName,
          deliveryDate: item.deliveryDate,
      })),
    });
    setIsLoading(false);
  };

  const handleDelete = async (documentId: string) => {
    if (window.confirm('Bu talebi silmek istediğinizden emin misiniz?')) {
      await api.delete(documentId);
      fetchRequests();
    }
  };

  return (
    <AdminLayout 
      isLoading={isLoading}
      breadcrumbs={[
        { path: '/admin/shipment-requests', name: 'Kargo Talepleri' }
      ]}>
        <Table
          columns={listData.columns}
          data={listData.data}
          meta={listData.meta}
        />

    </AdminLayout>
  );
};

export default ShipmentRequestList;
