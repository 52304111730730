import  { AxiosInstance } from "axios";
import { UserDetails } from "../models/user.model";
import axiosConfig from "./axios.config";
import qs from 'qs';

export default class UserApi {
    private apiClient: AxiosInstance = axiosConfig

    async login(identifier: string, password: string): Promise<any> {
        const response = await this.apiClient.post('/auth/local', { identifier, password },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': undefined
            }
        });
        return response.data;
    }

    async register(email: string, password: string): Promise<any> {
        delete this.apiClient.defaults.headers.common['Authorization']
        const response = await this.apiClient.post('/auth/local/register', { email, password, username: email });
        if (response.data.jwt) {
            localStorage.setItem('token', response.data.jwt);
            this.apiClient.defaults.headers.common['Authorization'] = `Bearer ${response.data.jwt}`;
        }
        return response.data;
    }

    async createUserDetails(user: UserDetails): Promise<any> {
        delete user.documentId;
        const response = await this.apiClient.post('/user-details', { data: user });
        return response.data;
    }

    async updateUserDetails(user: UserDetails): Promise<any> {
        const id = user.documentId
        delete user.documentId;
        delete user.id;
        delete user.skills?.id;
        delete user.info?.id;
        delete user.address?.id;
        const response = await this.apiClient.put(`/user-details/${id}`, {data: user});
        return response.data;
    }

    async me(): Promise<any> {
        const response = await this.apiClient.get('/users/me?populate=*');
        return response.data;
    }

    logout(): void {
        localStorage.removeItem('token');
    }

    async getUserDetails(id: number): Promise<UserDetails> {
        const filters = qs.stringify({
            populate: '*',
            filters: {
                user: {
                    documentId: {
                    $eq: id
                    }
                }
            }
        })
        const response = await this.apiClient.get<{data: UserDetails[]}>(`/user-details?${filters}`);
        return response.data.data[0];
    }
}
