import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { User } from '../models/user.model';
import UserApi from '../api/user.api';



interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  error: string | null;
  logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const userApi = new UserApi();

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      setLoading(true);
      if(localStorage.getItem('token')){
        const userData = await userApi.me();
        setUser(userData);
      }
    } catch (err) {
      setError('Kullanıcı bilgileri yüklenirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    userApi.logout(); // localStorage'dan token'ı siler
    setUser(null); // Kullanıcı durumunu sıfırlar
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading, error, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
