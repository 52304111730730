export const ApplicationStatusColors = {
    "waiting_review": "yellow-500",
    "under_review": "blue-500",
    "rejected": "red-500",
    "completed": "green-500"
}

export const ApplicationStatusLabels = {
    "waiting_review": "Beklemede",
    "under_review": "İncelemede",
    "rejected": "Reddedildi",
    "completed": "Tamamlandı"
}