import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {  FaSignOutAlt, FaChevronDown, FaSignInAlt, FaHome } from 'react-icons/fa';
import { useUser } from '../../contexts/UserContext';

interface LayoutProps {
  pageName?: string;
  children: ReactNode;
  breadcrumbs?: { path: string; name: string }[];
}


const Layout: React.FC<LayoutProps> = ({ children, breadcrumbs }) => {
  const { user, logout } = useUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const logo = require('../../assets/kadineli-logo.png');

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    await logout();
    navigate('/auth/login');
  };


  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <header className="text-gray-600 body-font border-b border-custom-yellow-green-100 bg-white">
        <div className="container mx-auto flex flex-wrap p-3 flex-col md:flex-row items-center">
          <Link to="/" className="flex title-font font-medium items-center text-gray-900  ">
            <img src={logo} alt="Logo" className="w-32" />   
          </Link>
          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            {user ? (
              <>
                <div className="relative" ref={dropdownRef}>
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="mr-5 hover:text-gray-900 flex items-center"
                  >
                    Taleplerim <FaChevronDown className="ml-1" />
                  </button>
                  {dropdownOpen && (
                    <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                      <Link to="/equipment-requests" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Cihaz Taleplerim</Link>
                      <Link to="/service-requests" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Servis Taleplerim</Link>
                      <Link to="/shipment-requests" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Kargo Taleplerim</Link>
                      <Link to="/product-requests" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Ürün Taleplerim</Link>
                    </div>
                  )}
                </div>
                <Link to="/profile" className="mr-5 hover:text-gray-900">Profilim</Link>
                <span className="mr-5">{user.username}</span>
                <button onClick={handleLogout} className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base">
                  <FaSignOutAlt className="mr-1" /> Çıkış
                </button>
              </>
            ) : (
              <>
                <Link to="/" className="mr-5 hover:text-gray-900">Anasayfa</Link>
        
                <Link to="/apply" className="mr-5 hover:text-gray-900">
                  <button className="inline-flex items-center bg-lemon-ginger hover:bg-texas-rose border-0 py-1 px-3 focus:outline-none hover:bg-custom-yellow-green-600 rounded text-base text-white">
                    <FaSignInAlt className="mr-1" />     Hemen Başvur
                  </button>
                </Link>
              </>
            )}
          </nav>
        </div>
      </header>
      {breadcrumbs && (
        <div className="container mx-auto px-4 py-2">
          <nav className="text-sm breadcrumbs">
            <ul className="flex items-center space-x-2">
              {breadcrumbs.map((crumb, index) => (
                <li key={index} className="flex items-center">
                  {index > 0 && <span className="mx-2">/</span>}
                  {index === 0 ? (
                    <FaHome className="mr-1" />
                  ) : null}
                  <Link to={crumb.path} className="text-custom-yellow-green-600 hover:text-custom-yellow-green-800">
                    {crumb.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
      <div className="container mx-auto h-full">
        {children}
      </div>
    </div>
  );
};

export default Layout;
