import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminLayout from '../../../components/Layout/admin_layout';
import EquipmentRequestApi from '../../../api/equipmentRequest.api';
import EquipmentBrandsApi from '../../../api/equipmentBrands.api';
import { EquipmentRequest } from '../../../models/equipmentRequest.model';
import { EquipmentBrand } from '../../../models/equipmentBrand.model';
import { EquipmentModel } from '../../../models/equipmentModel.model';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { RequestStatusLabels } from '../../../constants/equipment-request.enum';

const EquipmentRequestForm: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const navigate = useNavigate();
  const api = new EquipmentRequestApi();
  const equipmentBrandsApi = new EquipmentBrandsApi();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState<Partial<EquipmentRequest>>({
    deliveryDate: '',
    deliveryAddress: '',
    paymentType: 'Nakit / Havale',
    equipmentBrand: '',
    equipmentModel: '',
    request_status:'',
  });

  const [equipmentBrands, setEquipmentBrands] = useState<EquipmentBrand[]>([]);
  const [equipmentModels, setEquipmentModels] = useState<EquipmentModel[]>([]);

  useEffect(() => {
    const fetchRequest = async () => {
      if (documentId) {
        setIsLoading(true);
        try {
          const data = await api.getById(documentId);
          setFormData({
            ...data,
            equipmentBrand: data.equipmentBrand?.documentId || '',
            equipmentModel: data.equipmentModel?.documentId || '',
          });
          if (data.equipmentBrand?.documentId) {
            await fetchEquipmentModels(data.equipmentBrand.documentId);
          }
        } catch (error) {
          console.error('Talep yüklenirken hata oluştu:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    const fetchEquipmentBrands = async () => {
      try {
        const brands = await equipmentBrandsApi.getAll();
        setEquipmentBrands(brands);
      } catch (error) {
        console.error('Ekipman markaları yüklenirken hata oluştu:', error);
      }
    };

    fetchEquipmentBrands();
    fetchRequest();
  }, [documentId]);

  const fetchEquipmentModels = async (brandId: string) => {
    try {
      setIsLoading(true);
      const models = await equipmentBrandsApi.getModels(brandId);
      setEquipmentModels(models);
    } catch (error) {
      console.error('Ekipman modelleri yüklenirken hata oluştu:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));

    if (name === 'equipmentBrand') {
      setFormData(prevData => ({ ...prevData, equipmentModel: '' }));
      await fetchEquipmentModels(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const applicationId = new URLSearchParams(window.location.search).get('applicationId');

    try {
      setIsLoading(true);
      const requestData = {
        ...formData,
        equipmentBrand: { connect: [ formData.equipmentBrand ] },
        equipmentModel: { connect: [ formData.equipmentModel ] },
        application: { connect: [ applicationId ] },
      };

      if (documentId) {
        await api.update(documentId, requestData);
      } else {
        if (!applicationId) {
          toast.error('Başvuru numarası bulunamadı!');
          return;
        }
        await api.create(requestData);
      }
      toast.success('Ekipman talebi başarıyla oluşturuldu!');
      navigate('/admin/equipment-requests');
    } catch (error) {
      console.error('Ekipman talebi oluşturulurken bir hata oluştu:', error);
      toast.error('Ekipman talebi oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AdminLayout 
      isLoading={isLoading}
      breadcrumbs={[
        { path: '/admin/equipment-requests', name: 'Cihaz Talepleri' },
        { path: '/equipment-requests/new', name: 'Yeni Ekipman Talebi' }
      ]}
    >
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="container mx-auto p-4">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deliveryDate">
             Talep Edilen Teslim Tarihi
            </label>
            <Input
              type="date"
              name="deliveryDate"
              value={formData.deliveryDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deliveryAddress">
              Teslim Adresi
            </label>
            <Input
              type="text"
              name="deliveryAddress"
              value={formData.deliveryAddress}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paymentType">
              Ödeme Tipi
            </label>
            <Select
              name="paymentType"
              value={formData.paymentType}
              onChange={handleChange}
              required
              items={[
                { label: 'Nakit / Havale', value: 'Nakit / Havale' },
                { label: 'Ürün Karşılığı Taksit', value: 'Ürün Karşılığı Taksit' },
                { label: 'Kredili', value: 'Kredili' },
              ]}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="equipmentBrand">
              Ekipman Markası
            </label>
            <Select
              name="equipmentBrand"
              value={formData.equipmentBrand}
              onChange={handleChange}
              required
              items={equipmentBrands.map((brand) => ({
                label: brand.name,
                value: brand.documentId,
              }))}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="equipmentModel">
              Ekipman Modeli
            </label>
            <Select
              name="equipmentModel"
              value={formData.equipmentModel}
              onChange={handleChange}
              required
              disabled={!formData.equipmentBrand}
              items={equipmentModels.map((model) => ({
                label: model.name,
                value: model.documentId,
              }))}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="requestStatus">
              Talep Durumu
            </label>
            <Select
              name="request_status"
              value={formData.request_status}
              onChange={handleChange}
              required
              items={[
                { label: RequestStatusLabels.under_review, value: 'under_review' },
                { label: RequestStatusLabels.waiting_payment, value: 'waiting_payment' },
                { label: RequestStatusLabels.payment_received, value: 'payment_received' },
                { label: RequestStatusLabels.on_delivery, value: 'on_delivery' },
                { label: RequestStatusLabels.delivered, value: 'delivered' },
                { label: RequestStatusLabels.rejected, value: 'rejected' },
                { label: RequestStatusLabels.cancelled, value: 'cancelled' },
                { label: RequestStatusLabels.waiting_review, value: 'waiting_review' },
              ]}
            />
          </div>
          <div className="flex items-center justify-end">
            <button
              className="bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {documentId ? 'Güncelle' : 'Oluştur'}
            </button>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default EquipmentRequestForm;
