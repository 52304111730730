import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminLayout from '../../../components/Layout/admin_layout';
import ServiceRequestApi from '../../../api/serviceRequest.api';
import { ServiceRequest } from '../../../models/serviceRequest.model';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServiceRequestForm: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const navigate = useNavigate();
  const api = new ServiceRequestApi();

  const [formData, setFormData] = useState<Partial<ServiceRequest>>({
    deviceSerialNo: '',
    serviceDate: '',
    description: '',
  });

  useEffect(() => {
    const fetchRequest = async () => {
      if (documentId) {
        try {
          const data = await api.getById(documentId);
          setFormData(data);
        } catch (error) {
          console.error('Talep yüklenirken hata oluştu:', error);
        }
      }
    };

    fetchRequest();
  }, [documentId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (documentId) {
        await api.update(documentId, formData);
      } else {
        await api.create(formData);
      }
      navigate('/admin/service-requests');
      toast.success('Servis talebi başarıyla oluşturuldu!');
    } catch (error) {
      console.error('Servis talebi oluşturulurken bir hata oluştu:', error);
      toast.error('Servis talebi oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  return (
    <AdminLayout 
      breadcrumbs={[
        { path: '/service-requests', name: 'Servis Talepleri' },
        { path: '/service-requests/new', name: 'Yeni Servis Talebi' }
      ]}
    >
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="container mx-auto p-4">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deviceSerialNo">
              Cihaz Seri Numarası
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="deviceSerialNo"
              type="text"
              name="deviceSerialNo"
              value={formData.deviceSerialNo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="serviceDate">
              Servis Tarihi
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="serviceDate"
              type="date"
              name="serviceDate"
              value={formData.serviceDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Açıklama
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-custom-yellow-green-500 hover:bg-custom-yellow-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {documentId ? 'Güncelle' : 'Oluştur'}
            </button>
          </div>
        </form>
      </div>
    </AdminLayout> 
  );
};

export default ServiceRequestForm;
