import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

/*
import EquipmentRequestList from './pages/equipment-requests/EquipmentRequestList';
import EquipmentRequestForm from './pages/equipment-requests/EquipmentRequestForm';
import EquipmentRequestDetail from './pages/equipment-requests/EquipmentRequestDetail';
import ShipmentRequestList from './pages/shipment-requests/ShipmentRequestList';
import ShipmentRequestForm from './pages/shipment-requests/ShipmentRequestForm';
import ShipmentRequestDetail from './pages/shipment-requests/ShipmentRequestDetail';

import Profile from './pages/profile/profile';
import ProductRequestDetail from './pages/product-requests/ProductRequestDetail';
import ProductRequestForm from './pages/product-requests/ProductRequestForm';
import ProductRequestList from './pages/product-requests/ProductRequestList';
import ServiceRequestDetail from './pages/service-requests/ServiceRequestDetail';
import ServiceRequestForm from './pages/service-requests/ServiceRequestForm';
import ServiceRequestList from './pages/service-requests/ServiceRequestList';

import Register from './pages/auth/register/Register';
import Login from './pages/auth/Login';
*/

import { UserProvider } from './contexts/UserContext';
import Home from './pages/public/home';
import Apply from './pages/public/application/apply';
import Login from './pages/auth/Login';
import ApplicationList from './pages/admin/applications/application.list';
import ApplicationDetail from './pages/admin/applications/application.detail';
import EquipmentRequestForm from './pages/admin/equipment-requests/equipment-request.form';
import EquipmentRequestList from './pages/admin/equipment-requests/equipment-request.list';
import EquipmentRequestDetail from './pages/admin/equipment-requests/equipment-request.detail';
import ShipmentRequestList from './pages/admin/shipment-requests/ShipmentRequestList';
import ShipmentRequestForm from './pages/admin/shipment-requests/ShipmentRequestForm';
import ShipmentRequestDetail from './pages/admin/shipment-requests/ShipmentRequestDetail';
import ProductRequestList from './pages/admin/product-requests/ProductRequestList';
import ProductRequestForm from './pages/admin/product-requests/ProductRequestForm';
import ProductRequestDetail from './pages/admin/product-requests/ProductRequestDetail';
import ServiceRequestForm from './pages/admin/service-requests/ServiceRequestForm';
import ServiceRequestDetail from './pages/admin/service-requests/ServiceRequestDetail';
import ServiceRequestList from './pages/admin/service-requests/ServiceRequestList';
import ApplicationNew from './pages/admin/applications/application.new';

// Scroll to top component
const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App: React.FC = () => {
  return (
    <UserProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/admin/applications" element={<ApplicationList />} />
          <Route path="/admin/applications/:id" element={<ApplicationDetail />} />
          <Route path="/admin/applications/new" element={<ApplicationNew />} />
          <Route path="/admin/equipment-requests/new" element={<EquipmentRequestForm /> } />
          <Route path="/admin/equipment-requests" element={<EquipmentRequestList />} />          
          <Route path="/admin/equipment-requests/:documentId" element={<EquipmentRequestDetail />} />
          <Route path="/admin/equipment-requests/:documentId/edit" element={<EquipmentRequestForm />} />
          
          <Route path="/admin/shipment-requests" element={<ShipmentRequestList />} />
          <Route path="/admin/shipment-requests/new" element={<ShipmentRequestForm />} />
          <Route path="/admin/shipment-requests/:documentId" element={<ShipmentRequestDetail />} />
          <Route path="/admin/shipment-requests/:documentId/edit" element={<ShipmentRequestForm />} />
          
          <Route path="/admin/service-requests" element={<ServiceRequestList />} />
          <Route path="/admin/service-requests/new" element={<ServiceRequestForm />} />
          <Route path="/admin/service-requests/:documentId" element={<ServiceRequestDetail />} />
          <Route path="/admin/service-requests/:documentId/edit" element={<ServiceRequestForm />} />
          
          <Route path="/admin/product-requests" element={<ProductRequestList />} />
          <Route path="/admin/product-requests/new" element={<ProductRequestForm />} />
          <Route path="/admin/product-requests/:documentId" element={<ProductRequestDetail />} />
          <Route path="/admin/product-requests/:documentId/edit" element={<ProductRequestForm />} />
          {/* <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/login" element={<Login />} />

    
          
          <Route path="/profile" element={<Profile />} /> */}
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
