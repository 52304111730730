import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Datepicker } from 'flowbite-react';
import Input from '../../../components/Input';
import ApplicationFormDataModel from '../../../models/applicationFormData.model';
import ApplicationApi from '../../../api/application.api';

// FormData tipini tanımlayalım


const ApplicationForm: React.FC = () => {

  const [formData, setFormData] = useState<ApplicationFormDataModel>({
    info: {
      firstName: '',
      lastName: '',
      idNumber: '',
      phoneNumber: '',
      birthDate: new Date().toISOString(),
    },
    address: {
      city: '',
      district: '',
      street: '',
      buildingNumber: '',
      doorNumber: '',
      floor: '',
    },
    skills: {
      sewing: false,
      experience: '',
      productTypes: [],
    },
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [, setIsSubmitted] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    const isCheckbox = type === 'checkbox';
  
    setFormData((prevState) => {
      const [section, field] = name.split('.');
      
      if (!field) {
        // Doğrudan formData'nın bir alanıysa (email veya password gibi)
        return { ...prevState, [name]: isCheckbox ? (e.target as HTMLInputElement).checked : value };
      }
      
      return {
        ...prevState,
        [section]: {
          ...(prevState[section as keyof ApplicationFormDataModel] as Record<string, unknown>),
          [field]: isCheckbox ? (e.target as HTMLInputElement).checked : value,
        },
      };
    });
  };

  const handleProductTypesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      skills: {
        ...prevState.skills,
        productTypes: checked
          ? [...prevState.skills.productTypes, value]
          : prevState.skills.productTypes.filter(type => type !== value),
      },
    }));
  };

  const validateStep = (step: number): boolean => {
    switch(step) {
      case 1:
        return formData.info.firstName !== '' &&
               formData.info.lastName !== '' &&
               formData.info.idNumber !== '' &&
               formData.info.phoneNumber !== '' &&
               formData.info.birthDate !== '';
      case 2:
        return formData.address.city !== '' &&
               formData.address.district !== '' &&
               formData.address.street !== '' &&
               formData.address.buildingNumber !== '' &&
               formData.address.doorNumber !== '' &&
               formData.address.floor !== '';
      case 3:
        return formData.skills.experience !== '' &&
               formData.skills.productTypes.length > 0;
      default:
        return false;
    }
  };

  const nextStep = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    } else {
      toast.error('Lütfen tüm alanları doldurun.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateStep(currentStep)) {
      toast.error('Lütfen tüm alanları doldurun.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    
    try {
      const applicationApi = new ApplicationApi();
      await applicationApi.createApplication(formData);

        // Başvuru tamamlandı durumunu ayarla
        setIsSubmitted(true);
        setCurrentStep(4);  // Yeni adıma geç
 
    } catch (error) {
      console.error('Kayıt sırasında bir hata oluştu:', error);
      toast.error('Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <>
            <h2 className="text-lg font-semibold mb-4">Kişisel Bilgiler</h2>
            <Input
              label="Ad"
              name="info.firstName"
              type="text"
              placeholder="Adınız"
              required
              value={formData.info.firstName}
              onChange={handleInputChange}
            />
            <Input
              label="Soyad"
              name="info.lastName"
              type="text"
              placeholder="Soyadınız"
              required
              value={formData.info.lastName}
              onChange={handleInputChange}
            />
            <Input
              label="T.C. Kimlik No"
              name="info.idNumber"
              type="text"
              placeholder="T.C. Kimlik Numaranız"
              required
              value={formData.info.idNumber}
              onChange={handleInputChange}
            />
            <Input
              label="Telefon Numarası"
              name="info.phoneNumber"
              type="tel"
              placeholder="Telefon Numaranız"
              required
              value={formData.info.phoneNumber}
              onChange={handleInputChange}
            />
            <div>
              <label htmlFor="info.birthDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Doğum Tarihi</label>
              <Datepicker
                name="info.birthDate"
                id="info.birthDate"
                language='tr'
                required
                value={new Date(formData.info.birthDate)}
                onChange={(date) => setFormData({ ...formData, info: { ...formData.info, birthDate: date?.toISOString()! } })}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h2 className="text-lg font-semibold mb-4">Adres Bilgileri</h2>
            <Input
              label="İl"
              name="address.city"
              type="text"
              placeholder="İl"
              required
              value={formData.address.city}
              onChange={handleInputChange}
            />
            <Input
              label="İlçe"
              name="address.district"
              type="text"
              placeholder="İlçe"
              required
              value={formData.address.district}
              onChange={handleInputChange}
            />
            <Input
              label="Sokak"
              name="address.street"
              type="text"
              placeholder="Sokak"
              required
              value={formData.address.street}
              onChange={handleInputChange}
            />
            <Input
              label="Bina No"
              name="address.buildingNumber"
              type="text"
              placeholder="Bina No"
              required
              value={formData.address.buildingNumber}
              onChange={handleInputChange}
            />
            <Input
              label="Kat"
              name="address.floor"
              type="text"
              placeholder="Kat"
              required
              value={formData.address.floor}
              onChange={handleInputChange}
            />
            <Input
              label="Daire No"
              name="address.doorNumber"
              type="text"
              placeholder="Daire No"
              required
              value={formData.address.doorNumber}
              onChange={handleInputChange}
            />
          </>
        );
      case 3:
        return (
          <>
            <h2 className="text-lg font-semibold mb-4">Beceriler</h2>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="skills.sewing"
                  name="skills.sewing"
                  aria-describedby="skills.sewing"
                  type="checkbox"
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                  checked={formData.skills.sewing}
                  onChange={handleInputChange}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="skills.sewing" className="text-gray-500 dark:text-gray-300">Dikiş yapabiliyorum</label>
              </div>
            </div>
            
            <div>
              <label htmlFor="skills.experience" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Deneyim</label>
              <select
                id="skills.experience"
                name="skills.experience"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={formData.skills.experience}
                onChange={handleInputChange}
                required
              >
                <option value="">Dikiş Deneyiminiz</option>
                <option value="less_than_one">1 Yıldan Az</option>
                <option value="one_to_three">1-3 Yıl</option>
                <option value="three_to_six">3-6 Yıl</option>
                <option value="more_than_six">6 Yıldan Fazla</option>
              </select>
            </div>
            
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Hangi ürünleri dikebilirsiniz?</label>
              <div className="grid grid-cols-2 gap-2 mt-2">
                {['Tişört', 'Pantolon', 'Etek', 'Bluz', 'Tayt', 'Ceket'].map(type => (
                  <div key={type} className="flex items-center">
                    <input
                      id={`product-${type}`}
                      name="skills.productTypes"
                      type="checkbox"
                      value={type}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      checked={formData.skills.productTypes.includes(type)}
                      onChange={handleProductTypesChange}
                    />
                    <label htmlFor={`product-${type}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{type}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <div className="text-center">
            <div className="mb-8 ">
              <svg className="mx-auto border-2 border-green-500 rounded-full p-4 animate-check w-16 h-16 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <p className="text-lg">Başvurunuz başarıyla alındı,
            En kısa sürede sizinle iletişime geçeceğiz.</p>
            <Link to="/">
              <button className="text-white mt-4 bg-custom-yellow-green-500 hover:bg-custom-yellow-green-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                Ana Sayfaya Dön
              </button>
            </Link>
          </div>
        );
      default:
        return null;
    }
  };

  console.log('Current form data:', formData); // Debugging için

  return (
    <div>
      <ToastContainer />
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:mt-10 lg:py-0">
          <div className="w-full dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 lg:mt-10">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-center  text-gray-900 md:text-2xl dark:text-white">
              {currentStep === 4 ? 'Başvuru Tamamlandı' : `Başvuru Formu - Adım ${currentStep}/3`}
            </h1>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center px-6 py- mt-4 mx-auto lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                {renderStep()}
                {currentStep < 4 && (
                  <div className={`flex ${currentStep === 1 ? 'justify-end' : 'justify-between'}`}>
                    {currentStep > 1 && (
                      <button
                        type="button"
                        onClick={prevStep}
                        className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Geri
                      </button>
                    )}
                    {currentStep < 3 ? (
                      <button
                        type="button"
                        onClick={nextStep}
                        className="text-white [&&]:bg-custom-yellow-green-500 hover:bg-custom-yellow-green-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        İleri
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="text-white [&&]:bg-custom-yellow-green-500 hover:bg-custom-yellow-green-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Kayıt Ol
                      </button>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ApplicationForm  ;